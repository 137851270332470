<template>
  <div id="document-progress-bar">
    <div class="progress-bar-container">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${ documentProgress }%`"
        aria-valuenow="documentProgress"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentProgressBar',
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'documentProgress',
    ]),
  },
}
</script>

<style lang="scss" scoped>
$gray2-light3: #E9E9E9;

div#document-progress-bar {
  div.progress-bar-container {
    min-height: 2em;
    margin-bottom: 1em;
    background-color: $gray2-light3;

    .progress-bar {
      height: 2em;
      background-color: $ct-ui-primary;
    }
  }
}
</style>
