var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "document-progress-bar" } }, [
    _c("div", { staticClass: "progress-bar-container" }, [
      _c("div", {
        staticClass: "progress-bar",
        style: `width: ${_vm.documentProgress}%`,
        attrs: {
          role: "progressbar",
          "aria-valuenow": "documentProgress",
          "aria-valuemin": "0",
          "aria-valuemax": "100",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }